
import { Options, Vue } from "vue-class-component";
import { Watch, Prop } from "vue-property-decorator";
import { translate } from "@/locales/i18n";
import { ISerie } from "@/types/api.type";
import { getImageUrl } from "@/share/fetch";
import Swiper, { Navigation, Pagination, A11y } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

Swiper.use([Navigation, Pagination]);

@Options({
  name: "Others",
  methods: {
    getImageUrl: getImageUrl,
  },
})
export default class Others extends Vue {
  @Prop()
  series: ISerie[] | undefined;
  /* -------------------------------------------------------------------------- */
  /*                                   States                                   */
  /* -------------------------------------------------------------------------- */
  t = translate;
  swiper: any = null;

  /* -------------------------------------------------------------------------- */
  /*                                   Methods                                  */
  /* -------------------------------------------------------------------------- */
  initSwiper(): any {
    const swiper: any = new Swiper(".slider-others-container", {
      slidesPerView: 3,
      spaceBetween: 5,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
      },
      allowTouchMove: false,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
        },
        // when window width is >= 480px
        640: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
      },
    });
    return swiper;
  }

  async mounted(): Promise<void> {
    setTimeout(() => {
      this.swiper = this.initSwiper();
    }, 1);
  }
}
