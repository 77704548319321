
import { Options, Vue } from "vue-class-component";
import { Watch, Prop } from "vue-property-decorator";
import { translate } from "@/locales/i18n";
import Swiper, { Navigation, Pagination, A11y } from "swiper";
import { ISerie } from "../../../types/api.type";
import "swiper/swiper-bundle.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

Swiper.use([Navigation, Pagination]);

import Work from "@/components/Work.vue";

@Options({
  name: "Works",
  components: {
    Work,
  },
})
export default class Works extends Vue {
  @Prop()
  series: ISerie[] | undefined;

  /* -------------------------------------------------------------------------- */
  /*                                   States                                   */
  /* -------------------------------------------------------------------------- */
  t = translate;
  swiper: any = null;

  /* -------------------------------------------------------------------------- */
  /*                                   Methods                                  */
  /* -------------------------------------------------------------------------- */
  initSwiper(): any {
    const swiper: any = new Swiper(".slider-works-container", {
      slidesPerView: 1,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      allowTouchMove: false,
      navigation: {
        nextEl: ".swiper-works-next",
        prevEl: ".swiper-works-prev",
      },
    });
    return swiper;
  }

  /* -------------------------------------------------------------------------- */
  /*                                   Watches                                  */
  /* -------------------------------------------------------------------------- */
  mounted() {
    setTimeout(() => {
      this.swiper = this.initSwiper();
    }, 1);
  }
}
