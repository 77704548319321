
import { Options, Vue } from "vue-class-component";
import Rellax from "rellax";
import { translate } from "@/locales/i18n";

@Options({
  name: "Introduct",
  components: {},
})
export default class Introduct extends Vue {
  active = "en";
  t = translate;

  mounted(): void {
    Rellax("#green", {
      speed: 5,
    });
    Rellax("#blue", {
      speed: 2,
    });
    Rellax("#charector", {
      speed: -2,
    });
  }
}
