
import { Options, Vue } from "vue-class-component";
import Rellax from "rellax";
import { translate } from "@/locales/i18n";

@Options({
  name: "Welcome",
  components: {},
})
export default class Welcome extends Vue {
  t = translate;
  mounted(): void {
    Rellax("#white-2", {
      speed: 3,
    });
    Rellax("#charector-2", {
      speed: 1,
    });
  }
}
