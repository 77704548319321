
import { Options, Vue } from "vue-class-component";
import Rellax from "rellax";
import { translate } from "@/locales/i18n";
import axios from "axios";
import { fetchPartners } from "../../share/fetch";

@Options({
  name: "Partner",
})
export default class Partner extends Vue {
  /* -------------------------------------------------------------------------- */
  /*                                   States                                   */
  /* -------------------------------------------------------------------------- */
  t = translate;
  partners: Record<string, any>[] = [];
  backendUrl = process.env.VUE_APP_BACKEND_URL;

  /* -------------------------------------------------------------------------- */
  /*                                   Methods                                  */
  /* ----------------------------z---------------------------------------------- */

  async mounted() {
    this.partners = await fetchPartners();
    Rellax("#white-4", {
      speed: 2,
      center: true,
    });
  }
}
