import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "work-wrapper container mx-auto px-10 py-10 justify-center" }
const _hoisted_2 = { class: "paper-wrapper" }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "font-mommi font-bold text-2xl md:hidden xl:block" }
const _hoisted_5 = { class: "text-2xl text-gray-600 md:hidden xl:block" }
const _hoisted_6 = { class: "text-2xl text-primary-orange font-bold" }
const _hoisted_7 = { class: "content mt-5 xl:mt-10" }
const _hoisted_8 = { class: "text-xl" }
const _hoisted_9 = ["id"]
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: "work-container relative",
    style: _normalizeStyle({
      background: '#f2f2f2',
      backgroundImage: `url(${_ctx.getImageUrl(
        _ctx.propData.data?.attributes?.serieBackground?.data
      )})`,
    })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["paper p-36 lg:p-24 flex items-center justify-center", { 'text-right': _ctx.propData.mode !== 'right' }]),
        style: _normalizeStyle([{ backgroundImage: `url(${_ctx.currentPaper})` }])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_link, {
              to: `/series/${_ctx.propData?.data?.id}`
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.propData.data.attributes?.nameJp), 1)
              ]),
              _: 1
            }, 8, ["to"]),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.propData.data.attributes?.nameEn), 1),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.propData.data.attributes?.nameTh), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", null, _toDisplayString(_ctx.propData.data.attributes[
                    _ctx.$i18n.locale == "th" ? "nameTh" : "nameJp"
                  ]), 1),
              _createElementVNode("div", null, " Story By: " + _toDisplayString(_ctx.propData.data.attributes?.storyAuthor) + " / Art By: " + _toDisplayString(_ctx.propData.data.attributes?.artAuthor), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.propData.data.attributes?.copyright || " "), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.propData.data.attributes?.publisher || " "), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.propData.data.attributes?.translation || " "), 1)
            ])
          ])
        ])
      ], 6),
      (_ctx.propData.data.attributes?.serieMascot?.data)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["work-charector", `${_ctx.propData.mode || 'left'}`]),
            id: `work-charector-${_ctx.propData.id}`
          }, [
            _createElementVNode("img", {
              src: _ctx.getImageUrl(_ctx.propData.data.attributes.serieMascot.data)
            }, null, 8, _hoisted_10)
          ], 10, _hoisted_9))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "divider",
        style: _normalizeStyle({ backgroundColor: _ctx.propData.shade || '#000' })
      }, null, 4)
    ])
  ], 4))
}