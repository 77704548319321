
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";
import Rellax from "rellax";
import { ISerie } from "../types/api.type";
import { getImageUrl } from "@/share/fetch";

interface IDataProps {
  data: ISerie;
  mode?: "left" | "right";
  shade?: string;
}

@Options({
  name: "Work",
  props: {
    data: {
      type: Object as PropType<IDataProps>,
      required: true,
    },
  },
  methods: {
    getImageUrl: getImageUrl,
  },
})
export default class Work extends Vue {
  backendUrl = process.env.VUE_APP_BACKEND_URL;

  get propData(): any {
    return this.data;
  }
  get currentPaper(): string {
    return this.propData.paper
      ? this.paper[this.propData.paper]
      : this.paper[1];
  }
  paper: Record<number, string> = {
    1: require("@/assets/images/papers/paper1.svg"),
    2: require("@/assets/images/papers/paper2.svg"),
    3: require("@/assets/images/papers/paper3.svg"),
  };

  mounted(): void {
    Rellax(`#work-charector-${this.propData.id}`, {
      speed: this.propData.mode === "left" ? -1 : 1,
      center: true,
    });
  }
}
