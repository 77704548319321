import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "landing-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_SideMenu = _resolveComponent("SideMenu")!
  const _component_Introduct = _resolveComponent("Introduct")!
  const _component_Welcome = _resolveComponent("Welcome")!
  const _component_Works = _resolveComponent("Works")!
  const _component_Others = _resolveComponent("Others")!
  const _component_Partner = _resolveComponent("Partner")!
  const _component_About = _resolveComponent("About")!
  const _component_Contact = _resolveComponent("Contact")!

  return (_openBlock(), _createBlock(_Transition, {
    mode: "out-in",
    name: "fade"
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loading, {
            key: 0,
            class: "min-h-screen"
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_SideMenu, { class: "hidden lg:block" }),
            _createVNode(_component_Introduct),
            _createVNode(_component_Welcome),
            _createVNode(_component_Works, {
              class: "hidden lg:block",
              series: _ctx.series
            }, null, 8, ["series"]),
            _createVNode(_component_Others, { series: _ctx.series }, null, 8, ["series"]),
            _createVNode(_component_Partner),
            _createVNode(_component_About),
            _createVNode(_component_Contact)
          ]))
    ]),
    _: 1
  }))
}