import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "side-menu-container" }
const _hoisted_2 = { class: "side-menu-wrapper" }
const _hoisted_3 = ["href", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_smooth_scroll = _resolveDirective("smooth-scroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu, index) => {
        return _withDirectives((_openBlock(), _createElementBlock("a", {
          href: menu,
          id: `menu-${index}`,
          key: `menu-${index}`,
          class: "menu-item font-mommi text-xl flex justify-center items-center"
        }, [
          _createTextVNode(_toDisplayString(index), 1)
        ], 8, _hoisted_3)), [
          [_directive_smooth_scroll]
        ])
      }), 128))
    ])
  ]))
}