
import { Options, Vue } from "vue-class-component";

@Options({
  name: "SideMenu",
  watch: {
    currentPage(val, old) {
      const oldElm = document.getElementById(`menu-${old}`);
      const newElm = document.getElementById(`menu-${val}`);

      oldElm?.classList.remove("active");
      newElm?.classList.add("active");
    },
  },
})
export default class SideMenu extends Vue {
  menus = {
    1: "#section1",
    2: "#section2",
    3: "#section3",
    4: "#section4",
    5: "#section5",
    6: "#section6",
  };
  currentPage = 1;

  mounted(): void {
    window.addEventListener("scroll", this.handleScroll);
  }
  destroyed(): void {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(): void {
    const scrollOffset = document.documentElement.scrollTop;
    const halfScreen = window.innerHeight / 1.7; // half of screen

    // case top of window
    if (scrollOffset < 100 && this.currentPage !== 1) {
      this.currentPage = 1;
      return;
    }

    for (const [index, menu] of Object.entries(this.menus)) {
      const hT = document.getElementById(`section${index}`)?.offsetTop;

      if (hT && halfScreen) {
        const elmOffset = Math.round(hT - halfScreen);

        if (
          scrollOffset > elmOffset - 100 &&
          scrollOffset < elmOffset + window.innerHeight
        ) {
          if (this.currentPage !== +index) {
            this.currentPage = +index;
          }
        }
      }
    }
  }
}
