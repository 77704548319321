
import { Options, Vue } from "vue-class-component";
import Introduct from "@/Layout/Landing/Introduct.vue";
import Welcome from "@/Layout/Landing/Welcome.vue";
import Works from "../Layout/Landing/Works/Works.vue";
import SideMenu from "../components/SideMenu.vue";
import Others from "../Layout/Landing/Works/Others.vue";
import Partner from "../Layout/Landing/Partner.vue";
import About from "../Layout/Landing/About.vue";
import Contact from "../Layout/Landing/Contact.vue";
import Loading from "../components/Loading.vue";
import { ISerie } from "@/types/api.type";
import { fetchSeries } from "@/share/fetch";
import { translate, currentLocale } from "@/locales/i18n";

@Options({
  name: "Landing",
  components: {
    Loading,
    SideMenu,
    Introduct,
    Welcome,
    Works,
    Others,
    Partner,
    About,
    Contact,
  },
})
export default class Landing extends Vue {
  backendUrl = process.env.VUE_APP_BACKEND_URL;
  loading = true;
  series: ISerie[] | undefined = undefined;

  /* -------------------------------------------------------------------------- */
  /*                                   Methods                                  */
  /* -------------------------------------------------------------------------- */
  async fetchData() {
    // enable loading anination
    this.loading = true;

    try {
      this.series = await fetchSeries();
    } catch (e) {
      // handle fetct fail --> to 404 page
      return this.$router.push("/404");
    }

    // delay loading animation
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }

  mounted() {
    this.fetchData();
  }
}
